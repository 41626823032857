html,
body,
#root {
  width: 100%;
  height: 100%;
  font-family: 'Nunito';
}

body {
  margin: 0;
}